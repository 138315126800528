.checkoutProduct{
    display: flex;
    margin-top: 20px;
    margin-bottom: 20px;
}

.checkoutProduct_info {
    padding-left: 20px;
}

.checkoutProduct_info >button {
    background: rgb(107, 107, 189);
    border: 1px solid;
    margin-top: 10px;
    border-top: #a88734 #9c7e31 #846a29;
    color: black;
}

.checkoutProduct_image {
    object-fit: contain;
    width: 180px;
    height: 180px;
}

.checkout_rating {
    display: flex;
}

.checkoutProduct_title {
    font-size: 17px;
    font-weight: 800;
}