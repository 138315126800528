.login {
    background-color: white;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login_logo {
    display: flex;
    margin: 30px 0;
    align-items: center;
}

.login_logoImage {
    color: #ff9f00;
}

.login_logoTitle {
    color: black;
}

.login_container {
    width: 300px;
    height: fit-content;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    border: 1px solid lightgray;
    padding: 20px;
}

.login_container > h1 {
    font-weight: 500;
    margin-bottom: 20px;
}

.login_container > form > h5 {
    margin-bottom:  5px;
}

.login_container > form > input {
    height: 30px;
    margin-bottom:  10px;
    background-color: white;
    width: 98%;
}

.login_container >p {
    margin-top: 15px;
    font-size: 12px;
}

.login_signInButton {
    background: #ff9f00;
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
}

.login_registerButton {
    border-radius: 2px;
    width: 100%;
    height: 30px;
    border: 1px solid;
    margin-top: 10px;
    border-color: darkgray;
}