.header{
    height: 60px;
    display: flex;
    align-items: center;
    background-color: rgb(0, 0, 0);
    position: sticky;
    top: 0;
    z-index: 100;
}

.header_logo{
    display: flex;
    align-items: center;
    color: orange;
    margin: 0 25px;
}

.header_logoImage{
    margin-right: 10px;
}

.header_logoTitle{
    text-decoration: none;
    border: 0;
    color: white;
}

.header__search{
    display: flex;
    flex: 1;
    align-items: center;
    border-radius: 24px;
    margin: 0 10px;
}

.header_search{
    height: 12px;
    padding: 10px;
    border: none;
    width: 100%;
}

.header_searchIcon{
    padding: 5px;
    height: 22px;
    background-color: orange;
}

.header__nav{
    display: flex;
    justify-content: space-evenly;
}

.nav__item{
    display: flex;
    flex-direction: column;
    margin-left: 10px;
    margin-right: 10px;
    color: white;
}

.nav__itemLineOne{
    font-size: 10px;
}
.nav__itemLineTwo{
    font-size: 13px;
    font-weight: 800;
}

.itemBasket{
    display: flex;
    align-items: center;
    color: white;
    margin-right: 50px;
    margin-right: 10px;
}
.nav_count{
    margin-left: 10px;
    margin-right: 10px;   
}

.link{
    text-decoration: none;
}