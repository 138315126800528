.product{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    margin: 20px;
    padding: 20px;
    max-height: 400px;
    min-width: 500px;
    background-color: white;
    z-index: 1;
}

.product_rating{
    display: flex;
}

.product > img {
    max-height: 200px;
    width: 100%;
    object-fit: contain;
    margin: 25px 0 0 15px
}

.product > button {
    background: rgb(151, 135, 135);
    border: 1px solid;
    margin-top: 10px;
    border-color: #a88734 #9c7e31 #846a29;
    color: #111;
}

.product_price{
    margin-top: 5px;
}

.product_info{
    height: 100px;
    margin-bottom: 15px;
}